import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import LayoutContext from '../../context/LayoutContext';
import TncEn from './TncEn';
import TncZh from './TncZh';

const Container = styled.div`
  h1 {
    font-weight: bold;
  }

  font-size: 12px;
  color: ${(props) => (props.theme.name === 'men' ? '#ffffff' : '#8397a8')};
  a {
    text-decoration: underline;
    color: ${(props) => (props.theme.name === 'men' ? '#23bbd2' : '#ff0000')};
  }

  @media screen and (max-width: 767px) {
    .content {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
`;

const Tnc = () => {
  const { t } = useTranslation();
  const layoutProps = useContext(LayoutContext);
  const gender = layoutProps.pageContext.gender;
  const { isMobileView } = useSelector((state) => state.common);

  return (
    <Layout
      seoSettings={{ title: t('terms_and_conditions') }}
      fullwidth={isMobileView}
    >
      <Container>
        {layoutProps.pageContext.locale === 'zh' ? (
          <>
            <TncZh gender={gender} isMobileView={isMobileView} />
            <TncEn gender={gender} isMobileView={isMobileView} />
          </>
        ) : (
          <>
            <TncEn gender={gender} isMobileView={isMobileView} />
            <TncZh gender={gender} isMobileView={isMobileView} />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Tnc;
